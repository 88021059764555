<template>
  <v-form
    ref="form"
    v-model="valid"
    @submit.prevent="checkForm"
    aria-autocomplete="off"
  >
    <v-row>
      <p class="description">
        {{ $t('message.account_step_2_desc') }}
      </p>

      <v-col cols="12" sm="12" md="12">
        <p v-if="successMessage">
          <v-alert
            text
            dense
            color="teal"
            border="left"
            type="success"
          >
            {{ successMessage }}
          </v-alert>
        </p>
        <p v-if="errors.length">
          <v-alert
            text
            dense
            type="error"
            v-for="(error, index) in errors"
            :key="index"
          >
            {{ error.title }}
          </v-alert>
        </p>

        <v-text-field
          v-model="email"
          :rules="emailRules"
          :label="$t('message.label_email')"
          required
        ></v-text-field>

        <v-text-field
          v-model="password"
          :rules="passwordRules"
          @input="passwordCheck"
          :label="$t('message.label_password')"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showPassword ? 'text' : 'password'"
          @click:append="showPassword = !showPassword"
          autocomplete="new-password"
        ></v-text-field>

        <div class="d-flex">
          <span class="mr-3 font-weight-bold text-danger" style="color: darkred;">{{ $t('message.weak') }}</span>
          <password
            v-model="password"
            :secureLength="10"
            :strength-meter-only="true"
          />
          <span class="ml-3 font-weight-bold text-success" style="color: darkred;">{{ $t('message.strong') }}</span>
        </div>

        <span class="font-weight-bold">{{ $t('message.minimal_password_level') }}</span><br>
        <span class="frmValidation" :class="{'frmValidation--passed' : password.length > 9 && password.length <= 32}"><i class="frmIcon fas" :class="password.length > 9 && password.length <= 32 ? 'fa-check' : 'fa-times'"></i> {{ $t('message.between_10_32_char') }}</span><br>
        <span class="frmValidation" :class="{'frmValidation--passed' :hasUppercase }"><i class="frmIcon fas" :class="hasUppercase ? 'fa-check' : 'fa-times'"></i> {{ $t('message.at_least_one_caps') }}</span><br>
        <span class="frmValidation" :class="{'frmValidation--passed' :hasLowercase }"><i class="frmIcon fas" :class="hasLowercase ? 'fa-check' : 'fa-times'"></i> {{ $t('message.at_least_one_lowercase') }}</span><br>
        <span class="frmValidation" :class="{'frmValidation--passed' : hasNumber }"><i class="frmIcon fas" :class="hasNumber ? 'fa-check' : 'fa-times'"></i> {{ $t('message.at_least_one_digit') }}</span><br>
        <span class="frmValidation" :class="{'frmValidation--passed' : hasSpecialChar }"><i class="frmIcon fas" :class="hasSpecialChar ? 'fa-check' : 'fa-times'"></i> {{ $t('message.at_least_one_special_char') }}</span><br>

        <v-text-field
          v-model="confirmPassword"
          :rules="confirmPasswordRules"
          class="mt-3"
          :label="$t('message.label_password_confirmation')"
          :type="showPassword ? 'text' : 'password'"
          autocomplete="new-password"
        ></v-text-field>
      </v-col>
    </v-row>

    <router-link to="/connexion" class="recovery">{{ $t('message.back_to_login') }}</router-link>

    <button type="submit" :disabled="!valid" class="btn">{{ $t('message.validate') }}</button>
  </v-form>
</template>

<script>
import LoginLayout from '../../layouts/LoginLayout'
import Vue from 'vue'
import Password from 'vue-password-strength-meter'
import config from '../../config'

export default {
  name: 'AccountSetupStep2',
  components: { Password },
  data: () => ({
    valid: false,
    showPassword: false,
    successMessage: null,
    errors: [],
    email: '',
    password: '',
    confirmPassword: '',
    hasNumber: false,
    hasLowercase: false,
    hasUppercase: false,
    hasSpecialChar: false
  }),
  created () {
    this.$emit('update:layout', LoginLayout)
    document.title = config.title + ' - ' + this.$t('message.activation_step_2')

    const email = this.$route.query.email

    if (email) {
      this.email = email
    }
  },
  computed: {
    passwordRules () {
      return [
        v => !!v || this.$t('message.mandatory_password')
      ]
    },
    confirmPasswordRules () {
      return [
        v => !!v || this.$t('message.mandatory_password_confirmation')
      ]
    },
    emailRules () {
      return [
        v => !!v || this.$t('message.mandatory_email'),
        v => /.+@.+\..+/.test(v) || this.$t('message.mandatory_valid_email')
      ]
    }
  },
  methods: {
    checkForm () {
      if (this.password !== this.confirmPassword) {
        this.errors = [{ title: this.$t('message.password_must_match') }]

        return
      }
      if (this.password.toLowerCase() === this.email.toLowerCase()) {
        this.errors = [{ title: this.$t('message.password_must_be_different_email') }]

        return
      }
      if (this.password.length < 10 || !this.hasNumber || !this.hasLowercase || !this.hasUppercase || !this.hasSpecialChar) {
        this.errors.push({ title: this.$t('message.password_not_strong_enough') })

        return
      }

      if (this.valid) {
        this.accountSetupStep2()
      }
    },
    accountSetupStep2 () {
      this.valid = false

      const token = this.$route.query.token

      const data = {
        token: token,
        email: this.email.toLowerCase(),
        password: this.password,
        confirmPassword: this.confirmPassword
      }

      const formData = new FormData()
      formData.append('data', JSON.stringify(data))

      Vue.prototype.$http
        .post(config.apiUrl + '/account-setup/step-2', formData)
        .then(response => {
          if (response.status === 200) {
            const data = response.data

            if (data) {
              this.successMessage = data

              setTimeout(() => {
                this.$store.dispatch('logout')
              }, 3000)

              this.errors = []
            }
          }
        })
        .catch(e => {
          const response = e.response

          if (response.status === 400 || response.status === 404) {
            const data = response.data
            this.errors = data.violations
          }

          this.valid = true
          this.successMessage = null
        })
    },
    passwordCheck () {
      this.hasNumber = /\d/.test(this.password)
      this.hasLowercase = /[a-z]/.test(this.password)
      this.hasUppercase = /[A-Z]/.test(this.password)
      this.hasSpecialChar = /[!@#\$%\^\&*\)\(+=._-]/.test(this.password)
    }
  }
}
</script>
